import {Storage, Auth} from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import {config} from 'stage.config';
import 'global.config';
import Root from './Root';
import * as serviceWorker from './serviceWorker';

// Initialize Google Tag Manager
if (config.gtmId) {
  TagManager.initialize({
    gtmId: config.gtmId,
    auth: config.gtmAuth,
    preview: config.gtmPreview,
  });
}

Auth.configure({
  identityPoolId: config.identityPoolId,
  region: config.region,
  userPoolId: config.userPoolId,
  userPoolWebClientId: config.userPoolWebClientId,
});

Storage.configure({
  level: 'protected',
  AWSS3: {
    bucket: config.uploadsBucket,
    region: config.region,
  },
});

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
