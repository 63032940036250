import {Spin} from 'antd';
import React from 'react';
import styled from 'styled-components';

type Props = {
  delay?: number;
  inline?: boolean;
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: ${(props: Props) => (props.inline ? 'relative' : 'absolute')};
  padding: 25px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const Spinner: React.FC<Props> = ({delay = 500, inline, ...props}) => (
  <Wrapper inline={inline}>
    <Spin delay={delay} {...props} />
  </Wrapper>
);
