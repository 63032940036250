import {IdcardOutlined, LockOutlined} from '@ant-design/icons/lib';
import {CognitoUserAttribute} from 'amazon-cognito-identity-js';
import {Button, Typography} from 'antd';
import {Auth} from 'aws-amplify';
import {FormikProps, withFormik} from 'formik';
import {FormItem, Input} from 'formik-antd';
import React, {useEffect, useRef} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';
import {config} from 'stage.config';
import {AuthState, IAuthProps} from 'types';
import {AnchorLink} from '../AnchorLink';
import {Extra} from './Shared';

const {Text} = Typography;

type FormValues = {
  email: string;
  password: string;
  passwordConfirm: string;
  captcha: string;
  amazonCustomerId?: string;
};

type UserAttributes = {
  email: string;
  'custom:amazon_customer_id'?: string;
};

const C: React.FC<FormikProps<FormValues> & IAuthProps> = ({
  isSubmitting,
  signInAsDemo,
  setSubmitting,
  setFieldValue,
  handleSubmit
}) => {
  const captchaRef = useRef<ReCAPTCHA | null>(null);

  useEffect(() => {
    if (!captchaRef.current) return;
    setTimeout(() => {
      // @ts-ignore
      const container: HTMLDivElement = captchaRef.current.captcha;
      const iframe = container.getElementsByTagName('iframe')[0];
      if (!iframe) return;
      iframe.tabIndex = -1;
    }, 500);
  }, []);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        if (!captchaRef.current) return;
        captchaRef.current.execute();
      }}>
      <FormItem name="email">
        <Input
          autoFocus
          name="email"
          type="email"
          size="large"
          prefix={<IdcardOutlined />}
          placeholder="Email"
        />
      </FormItem>
      <FormItem name="password">
        <Input.Password
          name="password"
          type="password"
          size="large"
          prefix={<LockOutlined />}
          placeholder="Password"
        />
      </FormItem>
      <ReCAPTCHA
        ref={captchaRef}
        sitekey={config.recaptchaSiteKey}
        tabindex={-1}
        size="invisible"
        onErrored={() => {
          setSubmitting(false);
        }}
        onExpired={() => {
          setSubmitting(false);
        }}
        onChange={captcha => {
          setFieldValue('captcha', captcha);
          handleSubmit();
          captchaRef?.current?.reset();
        }}
      />
      <FormItem name="passwordConfirm">
        <Input.Password
          name="passwordConfirm"
          type="password"
          size="large"
          prefix={<LockOutlined />}
          placeholder="Password confirmation"
        />
      </FormItem>
      <Button block size="large" type="primary" htmlType="submit" loading={isSubmitting}>
        Sign Up
      </Button>
      <Extra>
        Want to try? <AnchorLink onClick={signInAsDemo}>Sign in as demo user</AnchorLink>
      </Extra>
      <Extra>
        <Text type="secondary">
          By signing up you agree to MoonMail's{' '}
          <AnchorLink href={config.termsOfServiceUrl} external>
            Terms of Service
          </AnchorLink>
          ,{' '}
          <AnchorLink href={config.privacyPolicyUrl} external>
            Privacy Policy
          </AnchorLink>{' '}
          and{' '}
          <AnchorLink href={config.antiSpamPolicyUrl} external>
            Anti-Spam Policy
          </AnchorLink>
        </Text>
      </Extra>
    </form>
  );
};

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(6).required(),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password')], "passwords don't match")
    .required('password confirmation is required'),
  captcha: yup.string().required()
});

export const SignUpForm = withFormik<IAuthProps, FormValues>({
  validationSchema: schema,
  mapPropsToValues: ({email = '', password = '', amazonCustomerId}) => ({
    email,
    password,
    amazonCustomerId,
    passwordConfirm: '',
    captcha: ''
  }),
  handleSubmit: async (
    {email, password, amazonCustomerId, captcha},
    {props, setErrors, setSubmitting}
  ) => {
    const attributes: UserAttributes = {email};
    if (amazonCustomerId) {
      attributes['custom:amazon_customer_id'] = amazonCustomerId;
    }
    try {
      const {userConfirmed} = await Auth.signUp({
        username: email,
        password,
        attributes,
        validationData: [new CognitoUserAttribute({Name: 'recaptchaToken', Value: captcha})]
      });
      // sign in user if it is confirmed
      if (userConfirmed) {
        await Auth.signIn(email, password);
        // get current user, CognitoUser returned form Auth.signIn does not have attributes
        const currentUser = await Auth.currentAuthenticatedUser();
        props.setState({authState: AuthState.signedIn, currentUser});
      } // else show confirm view
      else {
        props.setState({authState: AuthState.confirmSignUp, email, password});
      }
    } catch (error) {
      setErrors({email: error.message});
    } finally {
      setSubmitting(false);
    }
  }
})(C);
