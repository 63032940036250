import styled from 'styled-components';

export const Extra = styled.div`
  margin-top: 1em;
  text-align: center;
  line-height: 1.6;
  a {
    white-space: nowrap;
  }
`;
