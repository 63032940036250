import {IdcardOutlined, LockOutlined} from '@ant-design/icons/lib';
import {Form, FormItem, Input} from 'formik-antd';
import {Button, Card} from 'antd';
import {Auth} from 'aws-amplify';
import {FormikProps, withFormik} from 'formik';
import React from 'react';
import * as yup from 'yup';
import {AuthState, IAuthProps} from 'types';
import {AnchorLink} from '../AnchorLink';
import {Extra} from './Shared';

interface IFormValues {
  email: string;
  code: string;
  password: string;
}

const C: React.FC<FormikProps<IFormValues> & IAuthProps> = ({
  isSubmitting,
  setSubmitting,
  setState,
  values,
  email
}) => {
  const resendCode = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setSubmitting(true);
    await Auth.forgotPassword(values.email);
    setSubmitting(false);
  };
  const backToSignIn = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setState({authState: AuthState.signIn, email: values.email});
  };
  return (
    <Card
      title="Reset your password"
      extra={<AnchorLink onClick={backToSignIn}>Back to Sign In</AnchorLink>}>
      <Form>
        {!email && (
          <FormItem name="email">
            <Input
              autoFocus
              name="email"
              type="email"
              size="large"
              prefix={<IdcardOutlined />}
              placeholder="Email"
            />
          </FormItem>
        )}
        <FormItem name="code">
          <Input
            name="code"
            type="text"
            size="large"
            prefix={<LockOutlined />}
            placeholder="Code"
          />
        </FormItem>
        <FormItem name="password">
          <Input.Password
            name="password"
            type="password"
            size="large"
            prefix={<LockOutlined />}
            placeholder="New password"
          />
        </FormItem>

        <Button block size="large" type="primary" loading={isSubmitting} htmlType="submit">
          Change password
        </Button>
        <Extra>
          Lost your code? <AnchorLink onClick={resendCode}>Resend code</AnchorLink>
        </Extra>
      </Form>
    </Card>
  );
};

const schema = yup.object().shape({
  code: yup.string().required(),
  password: yup
    .string()
    .min(6)
    .required()
});

export const ForgotPasswordSubmit = withFormik<IAuthProps, IFormValues>({
  validationSchema: schema,
  mapPropsToValues: ({email = '', code = '', password = ''}) => ({email, code, password}),
  handleSubmit: async ({email, code, password}, {props, setErrors, setSubmitting}) => {
    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      props.setState({authState: AuthState.signIn, email});
    } catch (error) {
      if (error.code === 'CodeMismatchException') {
        return setErrors({code: error.message});
      }
      setErrors({password: error.message});
    } finally {
      setSubmitting(false);
    }
  }
})(C);
