type Stage = 'dev' | 'prod';

const stage = (process.env.REACT_APP_STAGE as Stage) || 'dev';

const globalConfig = {
  marketplaceUrl: 'https://aws.amazon.com/marketplace/pp/B07YSNVTZ4',
  antiSpamPolicyUrl: 'https://support.moonmail.io/article/67-anti-spam-policy',
  privacyPolicyUrl: 'https://support.moonmail.io/article/66-privacy-policy',
  termsOfServiceUrl: 'https://support.moonmail.io/article/65-terms-of-service',
  pricingUrl: 'https://support.moonmail.io/article/121-moonmail-pricing',
  emailAntiSpamRequirementsUrl:
    'https://support.moonmail.io/article/111-email-anti-spam-requirements',
  importanceOfPermissionUrl: 'https://support.moonmail.io/article/115-the-importance-of-permission',
  supportEmail: 'support@moonmail.io',
  supportUrl: 'https://support.moonmail.io',
  docsUrl: 'https://docs.moonmail.io'
};

const stageConfig = {
  dev: {
    region: 'eu-west-1',
    appsyncApiUrl: 'https://xpqtqvx7g5gk7eysbqqy44qpz4.appsync-api.eu-west-1.amazonaws.com/graphql',
    graphqlApiUrl: 'https://mm-graphql.microapps-staging.com',
    contactsApiUrl: 'https://7qjdnpf4vc.execute-api.eu-west-1.amazonaws.com/dev',
    formUrl: 'http://mm-forms.microapps-staging.com',
    identityPoolId: 'eu-west-1:68f3e222-a917-4cfb-a078-ddd7a37763c0',
    userPoolId: 'eu-west-1_1hpv14ckH',
    userPoolWebClientId: '5up6l27jedunsb6rkkej46k4n6',
    serviceBucket: 'moonmail-dev-service-bucket',
    uploadsBucket: 'moonmail-dev-uploads-bucket',
    stripeKey: 'pk_test_Sm54ZDrYJypL2iMglZBOXoFd00h8h2qMXL',
    demoEmail: null,
    demoPassword: null,
    gtmId: 'GTM-WSR7NNK',
    gtmAuth: 'NaeRy3lJTBStL47KVe_j9Q',
    gtmPreview: 'env-7',
    recaptchaSiteKey: '6Ld4l8AUAAAAAJDPUByn_Z__iPq6MXVFZUry8kg1'
  },
  prod: {
    region: 'us-east-1',
    appsyncApiUrl: 'https://appsync.moonmail.io',
    graphqlApiUrl: 'https://graphql.moonmail.io',
    contactsApiUrl: 'https://client.moonmail.io',
    formUrl: 'https://forms.moonmail.io',
    identityPoolId: 'us-east-1:12c81d5a-1989-4108-a649-ae37c412b7dd',
    userPoolId: 'us-east-1_slqaoERcc',
    userPoolWebClientId: 'hk0ei7bagiqk0am9mtlcorikt',
    serviceBucket: 'moonmail-prod-service-bucket',
    uploadsBucket: 'moonmail-prod-uploads-bucket',
    stripeKey: 'pk_live_fZViIVDBNTyUy7MhtwS4vJYK00nQ1ZOqcc',
    demoEmail: 'demo@moonmail.io',
    demoPassword: 'moonmail',
    gtmId: 'GTM-WSR7NNK',
    gtmAuth: 'o5fy-itRoQ6zJkL5TFYIQw',
    gtmPreview: 'env-2',
    recaptchaSiteKey: '6Ld4l8AUAAAAAJDPUByn_Z__iPq6MXVFZUry8kg1'
  }
};

export const config = {...globalConfig, ...stageConfig[stage]};
