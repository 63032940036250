import {CognitoUser} from 'amazon-cognito-identity-js';
import {RcFile} from 'antd/lib/upload/interface';
import {FetchResult} from 'apollo-link';
import {ParsedQuery} from 'query-string';

export interface IUser extends CognitoUser {
  attributes: {
    'custom:account_id': string;
    email: string;
    email_verified: boolean;
    phone_number: string;
    phone_number_verified: boolean;
    sub: string;
  };
  [key: string]: any;
}

export interface IS3Object {
  bucket: string;
  region: string;
  key: string;
  mimeType: string;
  localUri: File | RcFile;
}

export type MutateFunction<Mutation, MutationVariables> = (
  variables: MutationVariables
) => Promise<FetchResult<Mutation>>;

export enum AuthState {
  signIn = 'signIn',
  signUp = 'signUp',
  signedIn = 'signedIn',
  confirmSignUp = 'confirmSignUp',
  forgotPassword = 'forgotPassword',
  forgotPasswordSubmit = 'forgotPasswordSubmit',
  requireNewPassword = 'requireNewPassword',
  init = 'init'
}

export type IAuthState = {
  authState: AuthState;
  currentUser?: IUser;
  email?: string;
  code?: string;
  password?: string;
  amazonCustomerId?: string;
};

export interface IAuthQueryParams extends ParsedQuery {
  email?: string;
  password?: string;
  code?: string;
  action?: string;
  amazonCustomerId?: string;
  status?: string
}

export interface IAuthProps extends IAuthState {
  setState(state: IAuthState): void;
  signInAsDemo(): void;
}
