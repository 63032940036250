import {IdcardOutlined, LockOutlined} from '@ant-design/icons/lib';
import {Form, FormItem, Input} from 'formik-antd';
import {Button, Card} from 'antd';
import {Auth} from 'aws-amplify';
import {FormikProps, withFormik} from 'formik';
import React from 'react';
import * as yup from 'yup';
import {AuthState, IAuthProps} from 'types';
import {AnchorLink} from '../AnchorLink';
import {Extra} from './Shared';

interface IFormValues {
  email: string;
  code: string;
}

const C: React.FC<FormikProps<IFormValues> & IAuthProps> = ({
  isSubmitting,
  setSubmitting,
  setState,
  values
}) => {
  const resendCode = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setSubmitting(true);
    await Auth.resendSignUp(values.email);
    setSubmitting(false);
  };
  const backToSignIn = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setState({authState: AuthState.signIn, email: values.email});
  };
  return (
    <Card
      title="Confirm Sign Up"
      extra={<AnchorLink onClick={backToSignIn}>Back to Sign In</AnchorLink>}>
      <Form>
        <FormItem name="email">
          <Input
            autoFocus
            name="email"
            type="email"
            size="large"
            disabled
            prefix={<IdcardOutlined />}
            placeholder="Email"
          />
        </FormItem>
        <FormItem
          name="code"
          extra="Please enter the confirmation code from the email we've sent to your email address.">
          <Input
            name="code"
            type="text"
            size="large"
            prefix={<LockOutlined />}
            placeholder="Confirmation code"
          />
        </FormItem>
        <Button block size="large" type="primary" loading={isSubmitting} htmlType="submit">
          Confirm
        </Button>
        <Extra>
          Lost your code? <AnchorLink onClick={resendCode}>Resend Code</AnchorLink>
        </Extra>
      </Form>
    </Card>
  );
};

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(),
  code: yup.string().required()
});

export const ConfirmSignUp = withFormik<IAuthProps, IFormValues>({
  validationSchema: schema,
  mapPropsToValues: ({email = '', code = ''}) => ({email, code}),
  handleSubmit: async ({email, code}, {props, setErrors, setSubmitting}) => {
    try {
      await Auth.confirmSignUp(email, code);
      // sign in user after email is confirmed
      await Auth.signIn(email, props.password);
      // get current user, CognitoUser returned form Auth.signIn does not have attributes
      const currentUser = await Auth.currentAuthenticatedUser();
      props.setState({authState: AuthState.signedIn, currentUser});
    } catch (error) {
      setErrors({code: error.message});
    } finally {
      setSubmitting(false);
    }
  }
})(C);
