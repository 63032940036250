import * as antd from 'antd';
import React from 'react';
import styled from 'styled-components';
import {AuthState, IAuthProps} from 'types';
import {SignInForm} from './SignInForm';
import {SignUpForm} from './SignUpForm';

const Card = styled(antd.Card)`
  .ant-tabs-nav-list {
    width: 100%;
  }
  .ant-tabs-nav-list .ant-tabs-tab {
    width: 50%;
    margin-right: 0;
    justify-content: center;
  }
`;

const tabList = [
  {
    key: AuthState.signIn,
    tab: 'Sign In'
  },
  {
    key: AuthState.signUp,
    tab: 'Sign Up'
  }
];

export const Combined: React.FC<IAuthProps> = props => {
  const handleTabChange = (key: string) => {
    props.setState({authState: AuthState[key as AuthState] as AuthState});
  };

  return (
    <Card tabList={tabList} onTabChange={handleTabChange} defaultActiveTabKey={props.authState}>
      {props.authState === AuthState.signIn && <SignInForm {...props} />}
      {props.authState === AuthState.signUp && <SignUpForm {...props} />}
    </Card>
  );
};
