import React from 'react';
import {IAuthState} from 'types';

export enum AccessLevels {
  ADMIN_ACCESS = 'adminAccess',
  WRITE_ACCESS = 'writeAccess',
  READ_ACCESS = 'readAccess'
}

export interface IUserContext {
  accountId: string;
  userId: string;
  userEmail: string;
  isDemoUser: boolean;
  accessLevels: AccessLevels[];
  onSignOut(state?: IAuthState): void;
}

export const UserContext = React.createContext<IUserContext>({
  accountId: '',
  userId: '',
  userEmail: '',
  isDemoUser: false,
  accessLevels: [],
  onSignOut: () => {}
});
