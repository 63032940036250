/* tslint:disable:react-hooks-nesting */
import {message} from 'antd';
import {Auth} from 'aws-amplify';
import {ILazyComponent} from 'lib/utils';
import qs from 'query-string';
import React, {Suspense, useEffect, useState} from 'react';
import styled from 'styled-components';
import {UserContext} from 'lib/userContext';
import {config} from 'stage.config';
import {AuthState, IAuthProps, IAuthQueryParams, IAuthState, IUser} from 'types';
import {Spinner} from '../Spinner';
import {Combined} from './Combined';
import {ConfirmSignUp} from './ConfirmSignUp';
import {ForgotPassword} from './ForgotPassword';
import {ForgotPasswordSubmit} from './ForgotPasswordSubmit';
import {RequireNewPassword} from './RequireNewPassword';

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 20px 100px;
  height: 100%;
  background: url(/bg.svg) no-repeat top center fixed,
    linear-gradient(to bottom, #252a3b 0%, #15294b 40%, #1d2e42 80%) fixed;
`;

const Content = styled.div`
  width: 380px;
  margin: 0 auto 50px;
  .ant-input-prefix {
    color: rgba(0, 0, 0, 0.25) !important;
  }
  .ant-card {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  }
`;

const Logo = styled.img`
  width: 200px;
  margin: 0 20px 30px 0;
`;

const initialState: IAuthState = {
  authState: AuthState.init
};

const showNotification = (status: string) => {
  switch (status) {
    case 'newAmazonCustomer':
      return message.info(<>Please register to activate your account.</>, 10);
    case 'existingAmazonCustomer':
      return message.info(
        <>
          The user attached to this amazon marketplace account already exists. <br />
          Please sign in to reactivate your subscription.
        </>,
        10
      );
  }
};

const allowedActions = [AuthState.signUp, AuthState.forgotPassword, AuthState.confirmSignUp];

export const withAuthenticator = (Cmp: ILazyComponent<any>) => {
  return () => {
    const [state, setState] = useState(initialState);

    const handleSignOut = async (state: IAuthState = {authState: AuthState.signIn}) => {
      await Auth.signOut();
      setState(state);
      window.history.pushState('', '', '/');
    };

    useEffect(() => {
      const directAuthState = ({
        email,
        password,
        code,
        action,
        amazonCustomerId
      }: IAuthQueryParams) => {
        setState({authState: action as AuthState, email, password, code, amazonCustomerId});
      };

      const directSignIn = async (email: string, password: string) => {
        try {
          const user = await Auth.signIn(email, password);
          if (user.challengeName && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            return setState({authState: AuthState.requireNewPassword, currentUser: user, email});
          }
          return await checkAuth();
        } catch (error) {
          if (error.code === 'UserNotConfirmedException') {
            return setState({authState: AuthState.confirmSignUp, email, password});
          }
          message.error(error.message);
          setState({authState: AuthState.signIn, email});
        } finally {
          window.history.pushState('', '', window.location.pathname);
        }
      };

      const directConfirmation = async (email: string, code: string) => {
        try {
          await Auth.confirmSignUp(email, code);
          message.success('Your account is confirmed, please sign in');
        } catch (error) {
          message.error(error.message);
        } finally {
          window.history.pushState('', '', window.location.pathname);
          setState({authState: AuthState.signIn, email});
        }
      };

      const checkAuth = async () => {
        try {
          const currentUser = await Auth.currentAuthenticatedUser({bypassCache: true});
          setState({authState: AuthState.signedIn, currentUser});
        } catch {
          setState({authState: AuthState.signIn});
        }
      };

      const {email, password, code, action, amazonCustomerId, status} = qs.parse(
        window.location.search
      ) as IAuthQueryParams;

      if (status) {
        setTimeout(() => showNotification(status), 1000);
      }
      if (allowedActions.includes(action as AuthState)) {
        directAuthState({email, password, code, action, amazonCustomerId});
      } else if (email && password) {
        // noinspection JSIgnoredPromiseFromCall
        directSignIn(email, password);
      } else if (email && code) {
        // noinspection JSIgnoredPromiseFromCall
        directConfirmation(email, code);
      } else {
        // noinspection JSIgnoredPromiseFromCall
        checkAuth();
      }
      Cmp.preload().then(() => {
        console.log('App is loaded');
      });
    }, []);

    const signInAsDemo = async () => {
      if (!config.demoEmail || !config.demoPassword) return;
      setState({authState: AuthState.init});
      const currentUser = await Auth.signIn(config.demoEmail, config.demoPassword);
      setState({authState: AuthState.signedIn, currentUser});
    };

    const renderAuthView = () => {
      const props: IAuthProps = {...state, setState, signInAsDemo};
      switch (state.authState) {
        case AuthState.confirmSignUp:
          return <ConfirmSignUp {...props} />;
        case AuthState.forgotPassword:
          return <ForgotPassword {...props} />;
        case AuthState.forgotPasswordSubmit:
          return <ForgotPasswordSubmit {...props} />;
        case AuthState.requireNewPassword:
          return <RequireNewPassword {...props} />;
        default:
          return <Combined {...props} />;
      }
    };

    const getAccessLevels = (currentUser: IUser) => {
      const session = currentUser.getSignInUserSession()!;
      const {payload} = session.getIdToken();
      return payload['cognito:groups'];
    };

    if (state.authState === AuthState.init) return <Spinner />;
    if (state.authState === AuthState.signedIn) {
      const currentUser = state.currentUser!;
      return (
        <Suspense fallback={<Spinner />}>
          <UserContext.Provider
            value={{
              onSignOut: handleSignOut,
              userEmail: currentUser.attributes.email,
              userId: currentUser.attributes.sub,
              accountId: currentUser.attributes['custom:account_id'],
              isDemoUser: currentUser.attributes.email === config.demoEmail,
              accessLevels: getAccessLevels(currentUser)
            }}>
            <Cmp />
          </UserContext.Provider>
        </Suspense>
      );
    }
    return (
      <Container>
        <Logo src="https://static.moonmail.io/moonmail-logo-white.svg" />
        <Content>{renderAuthView()}</Content>
      </Container>
    );
  };
};
