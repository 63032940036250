import {LockOutlined} from '@ant-design/icons/lib';
import {Form, FormItem, Input} from 'formik-antd';
import {Button, Card} from 'antd';
import {Auth} from 'aws-amplify';
import {FormikProps, withFormik} from 'formik';
import React from 'react';
import * as yup from 'yup';
import {AuthState, IAuthProps} from 'types';
import {AnchorLink} from '../AnchorLink';

interface IFormValues {
  password: string;
  passwordConfirm: string;
}

const C: React.FC<FormikProps<IFormValues> & IAuthProps> = ({isSubmitting, setState, email}) => {
  const backToSignIn = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setState({authState: AuthState.signIn, email});
  };
  return (
    <Card
      title="Set a new password"
      extra={<AnchorLink onClick={backToSignIn}>Back to Sign In</AnchorLink>}>
      <Form>
        <FormItem name="password">
          <Input.Password
            autoFocus
            name="password"
            type="password"
            size="large"
            prefix={<LockOutlined />}
            placeholder="Password"
          />
        </FormItem>
        <FormItem name="passwordConfirm">
          <Input.Password
            name="passwordConfirm"
            type="password"
            size="large"
            prefix={<LockOutlined />}
            placeholder="Password confirmation"
          />
        </FormItem>
        <Button block size="large" type="primary" loading={isSubmitting} htmlType="submit">
          Change password
        </Button>
      </Form>
    </Card>
  );
};

const schema = yup.object().shape({
  password: yup
    .string()
    .min(6)
    .required(),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password')], "passwords don't match")
    .required('password confirmation is required')
});

export const RequireNewPassword = withFormik<IAuthProps, IFormValues>({
  validationSchema: schema,
  mapPropsToValues: ({password = ''}) => ({password, passwordConfirm: ''}),
  handleSubmit: async ({password}, {props, setErrors, setSubmitting}) => {
    console.log(props.email);
    try {
      await Auth.completeNewPassword(
        props.currentUser,
        password,
        props.currentUser!.challengeParam!.requiredAttributes
      );
      props.setState({authState: AuthState.signIn, email: props.email});
    } catch (error) {
      if (error.code === 'CodeMismatchException') {
        return setErrors({password: error.message});
      }
      setErrors({password: error.message});
    } finally {
      setSubmitting(false);
    }
  }
})(C);
