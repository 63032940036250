import {IdcardOutlined} from '@ant-design/icons/lib';
import {Form, FormItem, Input} from 'formik-antd';
import {Button, Card} from 'antd';
import {Auth} from 'aws-amplify';
import {FormikProps, withFormik} from 'formik';
import React from 'react';
import * as yup from 'yup';
import {AuthState, IAuthProps} from 'types';
import {AnchorLink} from '../AnchorLink';
import {Extra} from './Shared';

interface IFormValues {
  email: string;
}

const C: React.FC<FormikProps<IFormValues> & IAuthProps> = ({isSubmitting, setState, values}) => {
  const backToSignIn = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setState({authState: AuthState.signIn, email: values.email});
  };
  const goToSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setState({authState: AuthState.forgotPasswordSubmit});
  };
  return (
    <Card
      title="Reset your password"
      extra={<AnchorLink onClick={backToSignIn}>Back to Sign In</AnchorLink>}>
      <Form>
        <FormItem name="email">
          <Input
            autoFocus
            name="email"
            type="email"
            size="large"
            prefix={<IdcardOutlined />}
            placeholder="Email"
          />
        </FormItem>
        <Button block size="large" type="primary" loading={isSubmitting} htmlType="submit">
          Send code
        </Button>
        <Extra>
          <AnchorLink onClick={goToSubmit}>I already have a code</AnchorLink>
        </Extra>
      </Form>
    </Card>
  );
};

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required()
});

export const ForgotPassword = withFormik<IAuthProps, IFormValues>({
  validationSchema: schema,
  mapPropsToValues: ({email = ''}) => ({email}),
  handleSubmit: async ({email}, {props, setErrors, setSubmitting}) => {
    try {
      await Auth.forgotPassword(email);
      props.setState({authState: AuthState.forgotPasswordSubmit, email});
    } catch (error) {
      if (error.code === 'UserNotFoundException') {
        return setErrors({email: 'Email does not exist'});
      }
      setErrors({email: error.message});
    } finally {
      setSubmitting(false);
    }
  }
})(C);
