import React, {ComponentType, LazyExoticComponent} from 'react';

export function enumKeys<E>(e: E): Array<keyof E> {
  return Object.keys(e) as Array<keyof E>;
}

export function enumValues<E>(e: E): Array<E> {
  return Object.values(e) as Array<E>;
}

/**
 * Calculates percentage rate
 * @param num
 * @param denom
 */
export const getRate = (num = 0, denom = 1) => {
  if (denom === 0) return 0;
  const ratio = num / denom;
  return (ratio > 1 ? 1 : ratio) * 100;
};

export interface ILazyComponent<T extends ComponentType<any>> extends LazyExoticComponent<T> {
  preload: () => Promise<any>;
}

/**
 * Lazy load component and add preload method
 * Call Component.preload() to fetch async chunks
 * @param factory
 */
export const lazyWithPreload = <T extends ComponentType<any>>(
  factory: () => Promise<{default: T}>
) => {
  const Component = React.lazy(factory) as ILazyComponent<T>;
  Component.preload = factory;
  return Component;
};

/**
 * Get random int in rage
 * @param min
 * @param max
 */
export const getRandomInt = (min: number, max: number) => {
  return Math.round(Math.random() * (max - min) + min);
};

/**
 * Converts a map of dynamic attributes into an array of attributes
 * @param attributesMap
 */
export function getDynamicAttributes(attributesMap: {[key: string]: any} | undefined = {}) {
  return Object.keys(attributesMap).map(Key => ({
    Key,
    ...(typeof attributesMap[Key] === 'object' && !(attributesMap[Key] instanceof Array)
      ? attributesMap[Key]
      : {Value: attributesMap[Key]})
  }));
}

/**
 * Converts an array of attributes into a map of dynamic attributes
 * @param dynamicAttributes
 */
export function fromDynamicToMapAttributes(dynamicAttributes: any[] = []) {
  return dynamicAttributes.reduce((result, item) => {
    const {Key, ...rest} = item;
    result[item.Key] = rest;
    return result;
  }, {});
}

/**
 *intersperse: Return an array with the separator interspersed between
 * each element of the input array.
 *
 * > intersperse([1,2,3], 0)
 * [1,0,2,0,3]
 */
export function intersperse(arr: any[], sep: any) {
  if (arr.length === 0) return [];
  return arr.slice(1).reduce((xs, x) => xs.concat([sep, x]), [arr[0]]);
}

/**
 * Checks if variable is undefined or null
 * @param variable
 */
export const isUndefined = (variable: any) => {
  return typeof variable === 'undefined' || variable === null;
};

/**
 * Capitalize firs letter
 * @param s - string
 */
export const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const downloadTextAsFile = ({
  fileName,
  text,
  type
}: {
  fileName: string;
  text: string;
  type: string;
}) => {
  const element = document.createElement('a');
  element.setAttribute('href', `data:${type};charset=utf-8,${encodeURIComponent(text)}`);
  element.setAttribute('download', fileName);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

/**
 * Returns zero if number is negative
 * @param n
 */
export const positiveOrZero = (n: number) => {
  return n > 0 ? n : 0;
};

/**
 * Makes sure that the object is of type object and is not null.
 */
export const isObject = (object: any) => object != null && typeof object === 'object';

export const pick = (obj: {[key: string]: any} | undefined, keys: string[]) => {
  const result: {[key: string]: any} = {};
  if (!obj) return result;
  keys.forEach(key => {
    if (obj[key] === undefined) return;
    result[key] = obj[key];
  });
  return result;
};
