import { LoadingOutlined } from '@ant-design/icons';
import { message, notification, Spin } from 'antd';
import React from 'react';

Spin.setDefaultIndicator(<LoadingOutlined style={{fontSize: 32}} spin />);

notification.config({
  top: 70
});

message.config({
  top: 70
});
