import {IdcardOutlined, LockOutlined} from '@ant-design/icons/lib';
import {Form, FormItem, Input} from 'formik-antd';
import {Button} from 'antd';
import {Auth} from 'aws-amplify';
import {FormikProps, withFormik} from 'formik';
import React from 'react';
import * as yup from 'yup';
import {AuthState, IAuthProps} from 'types';
import {AnchorLink} from '../AnchorLink';
import {Extra} from './Shared';

interface IFormValues {
  email: string;
  password: string;
}

const C: React.FC<FormikProps<IFormValues> & IAuthProps> = ({
  isSubmitting,
  setState,
  signInAsDemo,
  values
}) => {
  const resetPassword = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setState({authState: AuthState.forgotPassword, email: values.email});
  };

  return (
    <Form>
      <FormItem name="email">
        <Input
          autoFocus
          name="email"
          type="email"
          size="large"
          prefix={<IdcardOutlined />}
          placeholder="Email"
        />
      </FormItem>
      <FormItem name="password">
        <Input.Password
          name="password"
          type="password"
          size="large"
          prefix={<LockOutlined />}
          placeholder="Password"
        />
      </FormItem>
      <Button block size="large" type="primary" loading={isSubmitting} htmlType="submit">
        Sign In
      </Button>
      <Extra>
        Want to try? <AnchorLink onClick={signInAsDemo}>Sign in as demo user</AnchorLink>
        <br />
        Forgot your password? <AnchorLink onClick={resetPassword}>Reset password</AnchorLink>
      </Extra>
    </Form>
  );
};

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(),
  password: yup
    .string()
    .min(6)
    .required()
});

export const SignInForm = withFormik<IAuthProps, IFormValues>({
  validationSchema: schema,
  mapPropsToValues: ({email = '', password = ''}) => ({email, password}),
  handleSubmit: async ({email, password}, {props, setErrors, setSubmitting}) => {
    try {
      const user = await Auth.signIn(email, password);
      if (user.challengeName && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return props.setState({authState: AuthState.requireNewPassword, currentUser: user, email});
      }
      // get current user, CognitoUser returned form Auth.signIn does not have attributes
      const currentUser = await Auth.currentAuthenticatedUser();
      props.setState({authState: AuthState.signedIn, currentUser, email});
    } catch (error) {
      // render confirm view if user is not confirmed
      if (error.code === 'UserNotConfirmedException') {
        return props.setState({authState: AuthState.confirmSignUp, email, password});
      }
      if (error.code === 'NotAuthorizedException') {
        return setErrors({password: 'Invalid email or password'});
      }
      setErrors({password: error.message});
    } finally {
      setSubmitting(false);
    }
  }
})(C);
